import React from "react";
import "./styles/Home.css";
import { motion } from "framer-motion";

export default function Home() {
  const divVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { duration: 0.2, ease: "easeInOut" },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.2, ease: "easeInOut" },
    },
  };

  return (
    <motion.div
      className="home-container"
      variants={divVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="home-content">
        <h1>
          Hi!<br></br>I'm <span className="h1-name-color">Miguel Quinta.</span>
        </h1>
        <p>Front End Developer, Mobile Curious, UI/UX Lover.</p>
      </div>
    </motion.div>
  );
}
