import React from "react";
import "./styles/Footer.css";

export default function Footer() {
  return (
    <>
      <footer>
        <p>mvqdev@gmail.com</p>
        <p className="footer-separator">|</p>
        <p>Miguel Quinta</p>
      </footer>
    </>
  );
}
