import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./styles/Contact.css";
import { motion } from "framer-motion";

export default function Contact() {
  const divVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { duration: 0.2, ease: "easeInOut" },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.2, ease: "easeInOut" },
    },
  };

  const [emailState, setEmailState] = useState(null);
  const [messageState, setMessageState] = useState(null);

  function handleEmailValid(e) {
    if (
      !e.target.value &&
      !/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
    ) {
      setEmailState(false);
    } else {
      setEmailState(true);
    }
  }

  function handleMessageValid(e) {
    if (!e.target.value) {
      setMessageState(false);
    } else {
      setMessageState(true);
    }
  }

  function sendEmail(e) {
    e.preventDefault();

    if (emailState === true && messageState === true) {
      emailjs
        .sendForm(
          "service_vhg9fdf",
          "template_1mxnyxj",
          e.target,
          "user_822MfkFcX1HVQkyHlvjPH"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
      setEmailState(null);
      setMessageState(null);
    } else {
      if (emailState === null) {
        setEmailState(false);
      }
      if (messageState === null) {
        setMessageState(false);
      }
    }
  }

  return (
    <motion.div
      className="contact-container"
      variants={divVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="contact-content">
        <div className="div-p-position">
          <p>
            Let's <span className="p-dev-color">talk</span>.
          </p>
        </div>
        <div className="div-form-position">
          <form onSubmit={sendEmail}>
            <label htmlFor="name">Name</label>
            <div className="line"></div>
            <input
              className="contact-input"
              type="text"
              name="name"
              placeholder="Your Name"
            />

            {emailState || emailState === null ? (
              <label htmlFor="email">Email *</label>
            ) : (
              <label htmlFor="email" className="required-warning">
                Required/Invalid Email
              </label>
            )}
            <div className="line"></div>
            <input
              className="contact-input"
              type="email"
              name="email"
              placeholder="youremail@email.com"
              onChange={(e) => handleEmailValid(e)}
            />

            {messageState || messageState === null ? (
              <label htmlFor="message">Message *</label>
            ) : (
              <label htmlFor="message" className="required-warning">
                Required Message
              </label>
            )}
            <div className="line"></div>
            <textarea
              className="contact-input"
              type="text"
              name="message"
              placeholder="Please write your message"
              onChange={(e) => handleMessageValid(e)}
            />
            <button type="submit" className="button-send">
              Send
            </button>
          </form>
        </div>
      </div>
    </motion.div>
  );
}
