import React from "react";
import { SiLinkedin, SiGithub, SiTwitter } from "react-icons/si";
import "./styles/Nav.css";
import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <>
      <nav className="nav-browser">
        <div className="nav-icons-container">
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/in/miguel-vinga-da-quinta-73489620"
                target="_self"
              >
                <SiLinkedin />
              </a>
            </li>
            <li>
              <a href="https://github.com/mvquinta" target="_self">
                <SiGithub />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/mvqdev1" target="_self">
                <SiTwitter />
              </a>
            </li>
          </ul>
        </div>
        <div className="nav-pages-container">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/projects">Projects</Link>
            </li>
            <li>
              <Link to="/contact">Contact </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
