import React from "react";
import "./styles/Projects.css";
import {
  SiHtml5,
  SiCss3,
  SiJavascript,
  SiReact,
  SiKotlin,
  SiAndroid,
  SiFigma,
} from "react-icons/si";
import { motion } from "framer-motion";

export default function Projects() {
  const divVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { duration: 0.2, ease: "easeInOut" },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.2, ease: "easeInOut" },
    },
  };

  return (
    <motion.div
      className="projects-wrapper"
      variants={divVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="proj-container">
        <div className="div-proj-vertical-line">
          <div className="proj-title">
            <div className="proj-type">Web App</div>
            <h2>1+Pomodoro</h2>
            <ul>
              <li>
                <SiReact />
              </li>
              <li>
                <SiJavascript />
              </li>
              <li>
                <SiHtml5 />
              </li>
              <li>
                <SiCss3 />
              </li>
              <li>
                <SiFigma />
              </li>
            </ul>
          </div>
          <div className="proj-description">
            <p>
              Web App with a Pomodoro timer and an associated To-Do List.
              <br></br>Built with React, this was my first personal project.
              Main objective was to put in practice the acquired knowledge of
              React.<br></br>Used Framer Motion for animations and Modals are
              set-up with React Portal.
            </p>
          </div>
          <a
            className="projects-links"
            href="https://onemorepomodoro.netlify.app/"
            target="_self"
          >
            Live Web
          </a>
          <a
            className="projects-links"
            href="https://github.com/mvquinta/1-pomodoro"
            target="_self"
          >
            Git Repo
          </a>
        </div>
      </div>
      <div className="proj-container">
        <div className="div-proj-vertical-line">
          <div className="proj-title">
            <div className="proj-type">Mobile App</div>
            <h2>1+Password Generator</h2>
            <ul>
              <li>
                <SiKotlin />
              </li>
              <li>
                <SiAndroid />
              </li>
              <li>
                <SiFigma />
              </li>
            </ul>
          </div>
          <div className="proj-description">
            <p>
              Android Mobile App that creates a random password based on
              editable settings that evaluate its strength and complexity.
              <br></br>This was my first project with which I learnt how to code
              and managed to publish my own app in under four months.<br></br>I
              wanted to prove myself two things: if I could code and if I liked
              to code. I could and I liked it!
            </p>
          </div>
          <a
            className="projects-links"
            href="https://play.google.com/store/apps/details?id=com.mvqdev.onemorepassword"
            target="_self"
          >
            Play Store
          </a>
          <a
            className="projects-links"
            href="https://github.com/mvquinta/OneMorePassword"
            target="_self"
          >
            Git Repo
          </a>
        </div>
      </div>
      <div className="proj-container">
        <div className="div-proj-vertical-line">
          <div className="proj-title">
            <div className="proj-type">Web Site</div>
            <h2>Martires da Liberdade</h2>
            <ul>
              <li>
                <SiFigma />
              </li>
            </ul>
          </div>
          <div className="proj-description">
            <p>
              Website for Museu de Aveiro.<br></br>Professional project where I
              worked in the UI/UX of it.<br></br>Using Figma, I designed the
              layout of the site.
            </p>
          </div>
          <a
            className="projects-links"
            href="https://16maio1828.aveiro.pt/"
            target="_self"
          >
            Link
          </a>
        </div>
      </div>
    </motion.div>
  );
}
