import React from "react";
import ReactDom from "react-dom";
import { SiLinkedin, SiGithub, SiTwitter } from "react-icons/si";
import { Link } from "react-router-dom";
import "./styles/MobileMenu.css";

export default function MobileMenu({ valueSetToggleMobileMenu }) {
  function handleClose() {
    valueSetToggleMobileMenu(false);
  }

  return ReactDom.createPortal(
    <>
      <div className="overlay" />
      <div className="mobile-container">
        <nav>
          <div className="mobile-nav-pages-container">
            <ul>
              <li>
                <Link to="/" onClick={handleClose}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={handleClose}>
                  About
                </Link>
              </li>
              <li>
                <Link to="/projects" onClick={handleClose}>
                  Projects
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={handleClose}>
                  Contact{" "}
                </Link>
              </li>
            </ul>
          </div>
          <div className="mobile-separator" />
          <div className="mobile-nav-icons-container">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/in/miguel-vinga-da-quinta-73489620"
                  target="_self"
                >
                  <SiLinkedin />
                </a>
              </li>
              <li>
                <a href="https://github.com/mvquinta" target="_self">
                  <SiGithub />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/mvqdev1" target="_self">
                  <SiTwitter />
                </a>
              </li>
            </ul>
          </div>
          <div className="mobile-separator" />
          <button className="mobile-menu-close" onClick={handleClose}>
            Close
          </button>
        </nav>
      </div>
    </>,
    document.getElementById("portal")
  );
}
