import React, { useState } from "react";
import "./App.css";
import { Route, Switch, useLocation } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { AnimatePresence } from "framer-motion";
import MobileMenu from "./components/MobileMenu";
import Nav from "./components/Nav";
import Home from "./components/Home";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  const location = useLocation();
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);

  function mobileMenu() {
    toggleMobileMenu ? setToggleMobileMenu(false) : setToggleMobileMenu(true);
  }

  return (
    <div className="App">
      <div className="first-left-column">
        <button className="menu-button" onClick={mobileMenu}>
          <AiOutlineMenu />
        </button>
        {toggleMobileMenu ? (
          <MobileMenu valueSetToggleMobileMenu={setToggleMobileMenu} />
        ) : null}
      </div>
      <Nav className="nav-grid-pos" />
      <div className="second-left-column"></div>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.key}>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
      </AnimatePresence>
      <div className="third-left-column"></div>
      <Footer />
    </div>
  );
}

export default App;
