import React from "react";
import "./styles/About.css";
import {
  SiGithub,
  SiHtml5,
  SiCss3,
  SiJavascript,
  SiReact,
  SiKotlin,
  SiAndroid,
  SiFigma,
  SiVisualstudiocode,
  SiAndroidstudio,
  SiLinux,
  SiIntellijidea,
} from "react-icons/si";
import { motion } from "framer-motion";

export default function About() {
  const divVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { duration: 0.2, ease: "easeInOut" },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.2, ease: "easeInOut" },
    },
  };

  return (
    <motion.div
      className="about-container"
      variants={divVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="about-content">
        <p>
          I’m a self-taught
          <span className="p-dev-color"> Front End Developer</span> and former
          3D Artist.
        </p>
        <p>
          Passionate about learning, I embraced a career change looking to
          fulfill both my personal and professional needs. I’ve been running
          alongside different technologies all my life. Now, as a developer, I
          live among them.
        </p>
        <p>
          Juggling between my professional activity, family and life, has been a
          proof of <span className="p-dev-color">consistency</span> and{" "}
          <span className="p-dev-color">commitment</span>.
        </p>
        <p>
          <span className="p-dev-color">Skills</span> that I build upon
          everyday:
        </p>
        <ul>
          <div className="about-content-organizer">
            <li>
              <SiHtml5 />
            </li>
            <li>
              <SiCss3 />
            </li>
            <li>
              <SiJavascript />
            </li>
          </div>
          <div className="about-content-organizer">
            <li>
              <SiReact />
            </li>
            <li>
              <SiKotlin />
            </li>
            <li>
              <SiAndroid />
            </li>
          </div>
        </ul>
        <p>
          And some <span className="p-dev-color">Tools</span>:
        </p>
        <ul>
          <div className="about-content-organizer">
            <li>
              <SiGithub />
            </li>
            <li>
              <SiFigma />
            </li>
            <li>
              <SiLinux />
            </li>
          </div>
          <div className="about-content-organizer">
            <li>
              <SiVisualstudiocode />
            </li>
            <li>
              <SiIntellijidea />
            </li>
            <li>
              <SiAndroidstudio />
            </li>
          </div>
        </ul>
      </div>
    </motion.div>
  );
}

/*
      <div className="about-content">
        <p>
          I’m a self-taught
          <span className="p-dev-color">Front End Developer</span> and former 3D
          Artist.
        </p>
        <p>
          Passionate about learning, I embraced a career change looking to
          fullfill both my personal and professional needs. I’ve being running
          along side different technologies all my life. Now, as developer, I
          live among them.
        </p>
      </div>
*/

/*
      <div className="about-content">
        <p>
          I’ve been constantly learning over the past few months. Juggling
          between my professional activity, family and life, has been a proof of{" "}
          <span className="p-dev-color">consistency</span> and{" "}
          <span className="p-dev-color">commitment</span>.
        </p>
        <p>Skills that I build upon everyday:</p>
        <ul>
          <li>
            <SiHtml5 />
          </li>
          <li>
            <SiCss3 />
          </li>
          <li>
            <SiJavascript />
          </li>
          <li>
            <SiReact />
          </li>
          <li>
            <SiKotlin />
          </li>
          <li>
            <SiAndroid />
          </li>
          <li>
            <SiGithub />
          </li>
          <li>
            <SiFigma />
          </li>
        </ul>
      </div>*/
